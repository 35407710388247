<template>

<div class="question">

	<div class="question-text">
		
		<span v-html="question.title" />

		<router-link :to="{name: 'Recommendation.Compare', params: { filter: question.filter } }" class="question-text-more" v-if="question.filter"></router-link>
		
	</div>

	<com-columns v-if="window.is.mobile" />

	<div class="question-column" v-for="column in columns" :key="column" v-on:click="onColumnClick(column)"><app-tick v-if="value === column" /></div>

</div>

</template>

<script>

export default {

	components: {
		'com-columns': () => import('./Columns')
	},

	props: ['question', 'value'],

	data: function() {

		return {
			columns: 4
		}

	},

	methods: {

		onColumnClick: function(value) {

			this.$emit('click', {
				name: this.question.key,
				value: value
			})

		}

	}

}

</script>

<style scoped>

.question {
	display: flex;
	border-bottom: 1px solid #707070;
}

.is-mobile .question {
	flex-wrap: wrap;
	padding: 0px 10px;
	border-bottom: 0px;
}

.question-text {
	flex-grow: 1;
	color: #312D50;
	font-size: 24px;
	line-height: 29px;
	font-weight: 400;
	padding: 20px 50px 20px 20px;
}

.is-mobile .question-text {
	width: 100%;
	font-size: 18px;
	line-height: 22px;
	padding: 20px 32px 20px 0px;
}

.question-text-more {
	width: 32px;
	height: 32px;
	background-image: url(~@/assets/info.svg);
	background-position: 50% 50%;
	background-size: 10px auto;
	background-repeat: no-repeat;
	border-radius: 50%;
	position: absolute;
	cursor: pointer;
	background-color: #2A273E;
	right: 0px;
	top: 20px;
}

.is-mobile .question-text-more {
	width: 24px;
	height: 24px;
	background-size: 16px 16px;
	top: 22px;
}

.question-column {
	width: 150px;
	flex-shrink: 0;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.is-mobile .question-column {
	width: 25%;
	height: 40px;
	margin-bottom: 20px;
}

.question-column:nth-child(even) {
	border-top: 1px solid #F0F0F0;
	border-bottom: 1px solid #F0F0F0;
	border-right: 1px solid #F0F0F0;
}

.question-column:nth-child(odd) {
	background-color: #F0F0F0;
}

.question-column >>> .tick {
	position: relative;
	left: auto;
	top: auto;
}

</style>
